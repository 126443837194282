import {
  getMeasurementRangeLevelColor,
  getRelevantRangesForCriteria,
  MeasurementRange,
  MeasurementRangeCriteria,
  MeasurementRangeResult,
  renderNumericalRange,
} from '@piccolohealth/echo-common';
import { ChakraV3 } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';

interface RangeGroupTableProps {
  rangeResult: MeasurementRangeResult;
  criteria: MeasurementRangeCriteria;
}

export const MeasurementRangeGroupTable = (props: RangeGroupTableProps) => {
  const { rangeResult, criteria } = props;

  const applicableRangesForSexAndAge: MeasurementRange[] = getRelevantRangesForCriteria(
    rangeResult.rangeGroup.ranges,
    criteria,
  );

  return (
    <ChakraV3.Table.Root size="sm" variant="line" tableLayout="fixed" showColumnBorder>
      <ChakraV3.Table.Header>
        <ChakraV3.Table.Row>
          {applicableRangesForSexAndAge.map((range, index) => (
            <ChakraV3.Table.ColumnHeader
              key={`label-${index}`}
              py={1}
              textAlign="left"
              fontSize="xs"
            >
              {range.label}
            </ChakraV3.Table.ColumnHeader>
          ))}
        </ChakraV3.Table.Row>
      </ChakraV3.Table.Header>
      <ChakraV3.Table.Body>
        <ChakraV3.Table.Row>
          {applicableRangesForSexAndAge.map((r, index) => {
            const isInRange = !P.isNil(rangeResult.range) && P.isEqual(r, rangeResult.range);
            const baseColor = getMeasurementRangeLevelColor(r.level);

            return (
              <ChakraV3.Table.Cell
                key={`measurement-${index}`}
                py={1}
                textAlign="left"
                bg={`${baseColor}.100`}
                shadowColor={`${baseColor}.focusRing`}
                shadow={isInRange ? `inset 0px 0px 0px 2px var(--shadow-color)` : `none`}
                fontSize="xs"
                transition={`box-shadow 0.2s ease-in-out`}
              >
                {renderNumericalRange(r.measurement, rangeResult.rangeGroup.units ?? undefined)}
              </ChakraV3.Table.Cell>
            );
          })}
        </ChakraV3.Table.Row>
      </ChakraV3.Table.Body>
    </ChakraV3.Table.Root>
  );
};
