import { Settings as DateTimeSettings } from '@piccolohealth/util';
import { FeatureFlag, ReportTemplate } from '@piccolohealth/echo-common';
import React from 'react';

interface ReportContextProps {
  reportId: string;
  changesetId: string | null | undefined;
  reportTemplate: ReportTemplate;
  timezone: string;
  hasFeature: (featureFlag: FeatureFlag) => boolean;
  isDisabled: boolean;
}

export const ReportContext = React.createContext<ReportContextProps>(null as any);

export const ReportContextProvider = (props: React.PropsWithChildren<ReportContextProps>) => {
  const { children, reportId, changesetId, reportTemplate, timezone, hasFeature, isDisabled } =
    props;

  const value = React.useMemo(() => {
    // Set locale and timezone again in case report context is used without organization context.
    // E.g. we only use report context for SSR
    DateTimeSettings.defaultLocale = 'en-AU';
    DateTimeSettings.defaultZone = timezone;

    return {
      changesetId,
      reportId,
      timezone,
      reportTemplate,
      hasFeature,
      isDisabled,
    };
  }, [timezone, changesetId, reportId, reportTemplate, hasFeature, isDisabled]);

  return <ReportContext.Provider value={value}>{children}</ReportContext.Provider>;
};

export const useReport = () => {
  return React.useContext(ReportContext);
};
