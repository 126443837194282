import { Avatar, Box, Divider, HStack, Icon, Link, Spacer, Stack, Text } from '@chakra-ui/react';
import {
  MeasurementRange,
  MeasurementRangeGroup,
  renderNumericalRange,
} from '@piccolohealth/echo-common';
import { createColumnHelper, DataTable, Empty, ScrollArea, typedMemo } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import {
  ControlledTreeEnvironment,
  Tree,
  TreeItem,
  TreeItemRenderContext,
} from 'react-complex-tree';
import { FaExternalLinkAlt, FaRegFolder, FaRegFolderOpen } from 'react-icons/fa';
import { useRangeGroupsTreeState } from '../../hooks/useRangeGroupsTreeState';
import { TreeData } from '../ranges/utils';
import { MeasurementRangeGroupActionsMenu } from './MeasurementRangeGroupActionsMenu';

interface RangeTableData {
  age: string;
  units: string;
  [range: string]: MeasurementRange | string;
}

const CustomTreeItem = (props: {
  item: TreeItem<TreeData>;
  title: React.ReactNode;
  arrow: React.ReactNode;
  context: TreeItemRenderContext<string>;
  depth: number;
  children: React.ReactNode | null;
  showOnlyChildren?: boolean;
}) => {
  const { context, arrow, title, depth, children, showOnlyChildren } = props;

  const style = {
    ...(context.isFocused ? { bg: 'gray.100' } : {}),
    ...(context.isSelected ? { bg: 'gray.100' } : {}),
    _hover: {
      bg: 'gray.50',
    },
  };

  return (
    <Box {...(context.itemContainerWithChildrenProps as any)}>
      <HStack
        {...(context.itemContainerWithoutChildrenProps as any)}
        {...(context.interactiveElementProps as any)}
        {...style}
        align="start"
        spacing={2}
        pl={`${depth * 20}px`}
        py={1}
      >
        <Box w={4}>{arrow}</Box>
        {!showOnlyChildren && (
          <Icon fontSize="18px" as={context.isExpanded ? FaRegFolderOpen : FaRegFolder} />
        )}
        {title}
      </HStack>

      {children}
    </Box>
  );
};

const TreeItemMemo = typedMemo(CustomTreeItem);

interface ItemTitleProps {
  item: TreeItem<TreeData>;
}

const ItemTitle = (props: ItemTitleProps) => {
  const { item } = props;

  const text = P.run(() => {
    switch (item.data.type) {
      case 'root': {
        return null;
      }

      case 'rangeGroup': {
        return (
          <Stack
            spacing={1}
            align="start"
            justify="center"
            fontSize="sm"
            w="full"
            h="24px"
            borderLeftWidth="2px"
            borderLeftColor="gray.300"
            pl={2}
          >
            <HStack w="full" spacing={1}>
              <Text fontWeight="bold">{item.data.title}</Text>
              <Spacer />
              <MeasurementRangeGroupActionsMenu rangeGroup={item.data.rangeGroup} />
            </HStack>
          </Stack>
        );
      }

      case 'sex': {
        return (
          <Stack
            spacing={1}
            align="start"
            justify="center"
            fontSize="sm"
            w="full"
            h="24px"
            borderLeftWidth="2px"
            borderLeftColor="gray.300"
            pl={2}
          >
            <Text fontWeight="bold">{item.data.title}</Text>
          </Stack>
        );
      }

      case 'ranges': {
        const units = item.data.units ?? '';
        const groupedByAge = Object.entries(
          P.groupBy(item.data.ranges, (range) => {
            if (!range.age) {
              return 'No age';
            }

            return renderNumericalRange(range.age) ?? 'No age';
          }),
        );
        const data: RangeTableData[] = groupedByAge.map(([age, ranges]) => {
          return {
            age,
            units,
            ...P.mapValues(
              P.groupBy(ranges, (range) => range.label),
              (value) => value[0],
            ),
          };
        });

        const columnHelper = createColumnHelper<RangeTableData>();

        const columns = [
          columnHelper.accessor('age', {
            header: 'Age',
            cell: (props) => {
              return <Text fontWeight="bold">{props.row.original.age ?? 'No age'}</Text>;
            },
            minSize: 100,
            maxSize: 150,
          }),
          ...item.data.ranges.map((range) =>
            columnHelper.accessor(range.label, {
              header: range.label,
              cell: () => {
                return renderNumericalRange(range.measurement);
              },
              minSize: 100,
              maxSize: 200,
            }),
          ),
          columnHelper.accessor('units', {
            header: 'Units',
            cell: (props) => {
              return <Text>{props.row.original.units}</Text>;
            },
            minSize: 100,
            maxSize: 150,
          }),
        ];

        return (
          <Stack spacing={1}>
            <Text fontWeight="semibold">Ranges</Text>
            <DataTable
              columns={columns}
              data={data}
              size="sm"
              renderEmpty={() => <Empty title="No data found" />}
            />

            <Divider />

            <Text fontWeight="semibold">Sources</Text>
            <Stack fontSize="sm">
              {item.data.sources.map((source) => (
                <Link key={source.url} href={source.name} isExternal>
                  <HStack>
                    <FaExternalLinkAlt />
                    <Text>{source.name}</Text>
                  </HStack>
                </Link>
              ))}
            </Stack>
          </Stack>
        );
      }
    }
  });

  const icon = P.run(() => {
    switch (item.data.type) {
      case 'root':
        return null;
      case 'rangeGroup':
        return <Avatar name="G" size="sm" boxSize="18px" fontWeight="bold" bg="blue.500" />;
      case 'sex':
        return <Avatar name="S" size="sm" boxSize="18px" fontWeight="bold" bg="red.500" />;
      case 'ranges':
        return null;
    }
  });

  return (
    <HStack w="full" align="start" lineHeight="normal">
      {icon}
      {text}
      <Spacer />
    </HStack>
  );
};

const ItemTitleMemo = typedMemo(ItemTitle);

interface Props {
  rangeGroups: MeasurementRangeGroup[];
}

export const MeasurementRangeGroupsTree = (props: Props) => {
  const { rangeGroups } = props;

  const treeState = useRangeGroupsTreeState({ rangeGroups });

  return (
    <ControlledTreeEnvironment<TreeData>
      items={treeState.treeState}
      getItemTitle={(item) => item.data.title}
      viewState={treeState.viewState}
      onFocusItem={treeState.onFocusItem}
      onExpandItem={treeState.onExpandItem}
      onCollapseItem={treeState.onCollapseItem}
      renderItemTitle={({ item }) => <ItemTitleMemo item={item} />}
      renderItem={(props) => {
        return (
          <TreeItemMemo
            title={props.title}
            arrow={props.arrow}
            context={props.context}
            item={props.item}
            depth={props.depth}
            showOnlyChildren={props.item.data.type === 'ranges'}
          >
            {props.children}
          </TreeItemMemo>
        );
      }}
    >
      <ScrollArea h="full" w="full" p={2} border="1px solid" borderColor="gray.300" rounded="xl">
        <Tree treeId="RG-TREE" rootItem="RG-ROOT" treeLabel="Ranges tree" />
      </ScrollArea>
    </ControlledTreeEnvironment>
  );
};
