import * as Types from '@piccolohealth/echo-common';
import { useMutation, useQueryClient } from 'react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreateMeasurementRangeGroup } from '../mutations/createMeasurementRangeGroup.mutation';
import { getMeasurementRangeGroupsBaseKey } from './useMeasurementRangeGroupsQuery';

export const useCreateMeasurementRangeGroupMutation = (
  options?: MutationOptions<
    Types.CreateMeasurementRangeGroupRequest,
    Types.CreateMeasurementRangeGroupMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateMeasurementRangeGroup), {
    ...options,
    onSuccess: async (resp, variables, ctx) => {
      await options?.onSuccess?.(resp, variables, ctx);
      queryClient.invalidateQueries(getMeasurementRangeGroupsBaseKey({}));
    },
  });
};
