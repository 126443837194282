import { Box, Button, Flex, Heading, HStack, Icon, Spacer, Stack } from '@chakra-ui/react';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import 'react-complex-tree/lib/style-modern.css';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../../../components/generic/Modal';
import { useMeasurementMappingsAndVariantsQuery } from '../../../../graphql/hooks/useMeasurementMappingsAndVariantsQuery';
import { useUnusedMeasurementVariantsQuery } from '../../../../graphql/hooks/useUnusedMeasurementVariantsQuery';
import { useDicomFilter } from '../../hooks/useDicomFilter';
import { MeasurementMappingCreateModal } from './MeasurementMappingCreateModal';
import { MeasurementMappingsAndVariantsFilterHeader } from './MeasurementMappingsAndVariantsFilterHeader';
import { MeasurementMappingsAndVariantsTree } from './MeasurementMappingsAndVariantsTree';
import { UnusedMeasurementVariantsFilterHeader } from './UnusedMeasurementVariantsFilterHeader';

export const MeasurementMappingsAndVariantsEditor = () => {
  const measurementMappingsAndVariantsQuery = useMeasurementMappingsAndVariantsQuery({
    request: { pagination: { limit: 10000 } },
  });
  const unusedMeasurementVariantsFilterQuery = useUnusedMeasurementVariantsQuery({
    request: { pagination: { limit: 10000 } },
  });

  const measurementMappingsAndVariants =
    measurementMappingsAndVariantsQuery.data?.dicom.measurementMappingsAndVariants
      .mappingsAndVariants ?? [];
  const unusedMeasurementVariants =
    unusedMeasurementVariantsFilterQuery.data?.dicom?.unusedMeasurementVariants.variants ?? [];

  const dicomFilter = useDicomFilter({
    variants: unusedMeasurementVariants,
    mappingsAndVariants: measurementMappingsAndVariants,
  });

  const isLoading =
    measurementMappingsAndVariantsQuery.isLoading || unusedMeasurementVariantsFilterQuery.isLoading;

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Stack p={4} h="full">
      <HStack w="full" align="start" spacing={8}>
        <Stack w="full">
          <Heading size="md" pb={2}>
            Variants
          </Heading>
          <UnusedMeasurementVariantsFilterHeader filter={dicomFilter} />
        </Stack>
        <Stack w="full">
          <Flex w="full">
            <Heading size="md" pb={2}>
              Mappings
            </Heading>
            <Spacer />
            <Button
              onClick={() => showModal(MeasurementMappingCreateModal)}
              colorScheme="purple"
              size="sm"
              leftIcon={<Icon as={FaPlus} />}
              alignSelf="flex-end"
            >
              Create mapping
            </Button>
          </Flex>
          <MeasurementMappingsAndVariantsFilterHeader filter={dicomFilter} />
        </Stack>
      </HStack>
      <Box minH={0}>
        <MeasurementMappingsAndVariantsTree
          variants={dicomFilter.filteredVariants}
          mappings={dicomFilter.filteredMappings}
          isExpanded={dicomFilter.isExpanded}
        />
      </Box>
    </Stack>
  );
};
