import React from 'react';
import { Box, BoxProps } from 'chakra-ui-v3';
import { Spin } from '../Spin';

export const FadeLoader = (props: React.PropsWithChildren<{ isLoading?: boolean } & BoxProps>) => {
  const { isLoading, children, ...rest } = props;

  return (
    <Box {...rest} position="relative">
      {isLoading ? (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spin />
        </Box>
      ) : null}
      <Box
        opacity={isLoading ? 0 : 1}
        transition="opacity 0.5s ease-in-out"
        pointerEvents={isLoading ? 'none' : 'auto'}
      >
        {children}
      </Box>
    </Box>
  );
};
