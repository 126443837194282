import {
  getMeasurementRangeLevelColor,
  getRelevantRangesForCriteria,
  isMeasurementCriteriaInRange,
  MeasurementRangeCriteria,
  MeasurementRangeResult,
  transformRangesForGraphing,
} from '@piccolohealth/echo-common';
import { DateTime, P } from '@piccolohealth/util';
import { EChartsOption, HighlightPayload, LineSeriesOption } from 'echarts';
import React from 'react';
import { CHART_COLORS } from '../../components/charts/ChartsTheme';
import { ECharts } from '../../components/charts/ECharts';
import { useChartTooltipRef } from '../../components/charts/hooks/useChartTooltipRef';
import { MeasurementsChartTooltip } from './MeasurementsChartTooltip';
import { EChartsInstance } from 'echarts-for-react';

export type MeasurementSeries = {
  id: string;
  data: { x: string | number; y: number | null }[];
};
export type MeasurementChartData = {
  series: MeasurementSeries[];
  units: string | null;
  color: string;
  rangeInfo?: {
    criteria: MeasurementRangeCriteria;
    result: MeasurementRangeResult | null;
  };
};

/**
 * Calculate the settings for the Y-axis based on the maximum data value
 * ECharts is unable to automatically calculate the Y-axis settings based
 * in a nice way. So we need to calculate the min, max and interval manually.
 * The goal is to have a nice interval that divides up into a set number of dmarks
 */
const calculateYAxisSettings = (
  maxDataValue: number,
  numberOfMarks = 6,
  modifier = 1.4,
): {
  max: number;
  min: number;
  interval: number;
} => {
  const niceNumbers = [0.1, 0.2, 0.25, 0.5, 1, 2, 2.5, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000];

  const idealMax = maxDataValue * modifier;
  const idealInterval = idealMax / numberOfMarks;

  const interval = niceNumbers.reduce((prev, curr) => {
    return Math.abs(curr - idealInterval) < Math.abs(prev - idealInterval) ? curr : prev;
  }, 0);

  const min = 0;
  const max = parseFloat((Math.ceil(idealMax / interval) * interval).toPrecision(4));

  return { min, max, interval };
};

interface Props {
  data: MeasurementChartData[];
  ssr: boolean;
  width?: number;
  height?: number;
  onHoverDataIndex?: (dataIndex: number | null) => void;
}

export const MeasurementsChart = (props: Props) => {
  const tooltipRender = useChartTooltipRef({ ssr: props.ssr });

  const combinedData = React.useMemo(() => {
    const allXValues = Array.from(
      new Set(props.data.flatMap((d) => d.series.flatMap((s) => s.data.map((point) => point.x)))),
    );

    return props.data.map((d) => ({
      color: d.color || CHART_COLORS.green, // Default color if not specified
      units: d.units,
      rangeInfo: d.rangeInfo,
      series: d.series.map((s) => ({
        ...s,
        data: allXValues.map((x) => {
          const found = s.data.find((point) => point.x === x);
          return found ? found : { x, y: null };
        }),
      })),
    }));
  }, [props.data]);

  const relevantRanges = React.useMemo(() => {
    // If there is more than one series, we can't display the range data
    if (props.data.length !== 1) {
      return [];
    }

    const rangeInfo = props.data[0].rangeInfo;

    // If there is no range data, we can't display the range data
    if (!rangeInfo?.result) {
      return [];
    }

    return getRelevantRangesForCriteria(rangeInfo.result.rangeGroup.ranges, rangeInfo.criteria);
  }, [props.data]);

  const options: EChartsOption = React.useMemo(() => {
    const dataSeries: LineSeriesOption[] = combinedData.map((d, index) => ({
      type: 'line',
      name: d.series[0].id,
      data: d.series[0].data.map((point) => ({
        name: point.x as string,
        value: point.y,
      })),
      lineStyle: { color: d.color },
      itemStyle: { color: d.color },
      symbolSize: 8,
      symbol: 'emptyCircle',
      smooth: true,
      animation: false,
      yAxisIndex: index, // Assign the series to the appropriate Y-axis (0 for left, 1 for right)
    }));

    const graphableRanges = transformRangesForGraphing(relevantRanges);

    // Add a "fake" series using range data. We do this so Echarts can calculate min and max
    // for us automatically. We hide the series by setting opacity to 0.
    const rangeSeries: LineSeriesOption = {
      type: 'line',
      name: 'Range',
      showSymbol: false,
      showAllSymbol: false,
      tooltip: {
        show: false,
      },
      lineStyle: { opacity: 0 },
      data: graphableRanges.map((range) => ({
        value: [range.min, range.max],
      })),
    };

    const series = [...dataSeries, rangeSeries];

    // Add markArea to highlight the relevant ranges
    series[0].markArea = {
      emphasis: { disabled: true },

      data: graphableRanges.map((range) => {
        const rangeLevelColors = CHART_COLORS.rangeLevels as Record<string, string>;
        const color = rangeLevelColors[getMeasurementRangeLevelColor(range.level)];

        const min = range.min;
        const max = range.max;

        return [
          {
            yAxis: min,
            itemStyle: { color, opacity: P.isUndefined(min) ? 0 : 1 },
          },
          {
            yAxis: max,
            itemStyle: { color, opacity: P.isUndefined(max) ? 0 : 1 },
          },
        ];
      }),
    };

    const xAxisData = combinedData[0]?.series[0].data.map((point) =>
      DateTime.fromISO(point.x as string).toFormat('MM/yy'),
    );

    const leftYAxisSettings = calculateYAxisSettings(
      Math.max(
        ...[
          ...combinedData?.[0].series?.[0]?.data?.map((v) => v.y ?? 0),
          ...graphableRanges.map((range) => range.max ?? 0),
        ],
      ),
    );

    const rightYAxisSettings = calculateYAxisSettings(
      Math.max(...(combinedData?.[1]?.series?.[0].data?.map((v) => v.y ?? 0) ?? [0])),
    );

    return {
      grid: {
        top: 10,
        left: 10,
        right: 20,
        bottom: 10,
        show: true,
        containLabel: true,
      },

      xAxis: {
        type: 'category',
        data: xAxisData,
        boundaryGap: false,
        axisLine: { show: false, lineStyle: { color: CHART_COLORS.gray } },
      },
      yAxis: [
        {
          type: 'value',
          z: 3, // So appears on top of markArea
          axisLine: { show: true, lineStyle: { color: combinedData[0]?.color, width: 2 } },
          axisLabel: {
            color: CHART_COLORS.gray,
            formatter: (value) => `${value} ${combinedData[0]?.units || ''}`,
          },
          min: leftYAxisSettings.min,
          max: leftYAxisSettings.max,
          interval: leftYAxisSettings.interval,
        },
        {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: { color: combinedData[1]?.color ?? CHART_COLORS.lightGray, width: 2 },
          },
          axisLabel: {
            color: CHART_COLORS.gray,
            formatter: (value) => `${value} ${combinedData[1]?.units || ''}`,
          },
          splitLine: { show: false }, // Disable grid lines for the right Y-axis
          min: rightYAxisSettings.min,
          max: rightYAxisSettings.max,
          interval: rightYAxisSettings.interval,
        },
      ],
      tooltip: {
        trigger: 'axis',

        formatter: (params) => {
          const flatParams = P.flatten([params]);
          const data = flatParams[0];
          const value = data.value as number | null;
          const timestamp = DateTime.fromISO(data.name);
          const seriesName = data.seriesName ?? 'Unknown measurement';
          const units = combinedData[data.seriesIndex ?? 0]?.units ?? '';

          const rangeResult: MeasurementRangeResult | null = P.run(() => {
            const rangeInfo = combinedData[data.seriesIndex ?? 0]?.rangeInfo;

            if (!rangeInfo?.result) {
              return null;
            }

            const criteria = rangeInfo.criteria;
            const ranges = rangeInfo.result.rangeGroup.ranges;

            const matchingRange =
              ranges.find((range) =>
                isMeasurementCriteriaInRange(range, {
                  ...criteria,
                  measurement: value,
                }),
              ) ?? null;

            return {
              range: matchingRange,
              rangeGroup: rangeInfo.result.rangeGroup,
            };
          });

          return (
            tooltipRender(
              <MeasurementsChartTooltip
                value={value}
                timestamp={timestamp}
                seriesName={seriesName}
                units={units}
                rangeResult={rangeResult}
              />,
            ) ?? '-'
          );
        },
      },
      series,
      animationDuration: 400,
    };
  }, [combinedData, relevantRanges, tooltipRender]);

  return (
    <ECharts
      options={options}
      width={props.width}
      height={props.height}
      onChartReady={(instance: EChartsInstance) => {
        instance.on('highlight', (params: HighlightPayload) => {
          const dataIndex = params?.batch?.[0].dataIndex;
          if (!P.isNil(dataIndex)) {
            props.onHoverDataIndex?.(dataIndex);
          }
        });

        instance.on('downplay', () => {
          props.onHoverDataIndex?.(null);
        });
      }}
    />
  );
};
