export const CHART_COLORS = {
  white: '#FFFFFF',
  lightGray: '#E0E6F1',
  gray: '#718096',
  green: '#00A676',
  rangeLevels: {
    green: '#dcfce7',
    yellow: '#fef9c3',
    orange: '#ffedd5',
    red: '#fee2e2',
  },
} as const;
