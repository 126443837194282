import React from 'react';
import { Box } from '@chakra-ui/react';
import type { EChartsOption } from 'echarts';
import type EChartsReact from 'echarts-for-react';
import type { EChartsInstance } from 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  MarkAreaComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { SVGRenderer } from 'echarts/renderers';
import { forwardRef } from 'react';
import { useMeasure } from 'react-use';

echarts.use([
  SVGRenderer,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  PieChart,
  LegendComponent,
  MarkAreaComponent,
]);

interface Props {
  options: EChartsOption;
  width?: number;
  height?: number;
  onChartReady?: (instance: EChartsInstance) => void;
}

const EChartsSSR = (props: Omit<Props, 'ssr'>) => {
  const { options, width, height } = props;

  const __html = React.useMemo(() => {
    const chart = echarts.init(null, null, {
      renderer: 'svg',
      ssr: true,
      width,
      height,
    });

    chart.setOption({
      ...options,
      animation: false,
    });

    return chart.renderToSVGString();
  }, [height, options, width]);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html,
      }}
    />
  );
};

const EChartsClient = forwardRef<EChartsReact, Omit<Props, 'ssr'>>((props, ref) => {
  const { onChartReady, options } = props;
  const [containerRef, size] = useMeasure<HTMLDivElement>();
  const isVisible = size.height > 0 && size.width > 0;

  const onChartReadyPrime = React.useCallback(
    (instance: EChartsInstance) => {
      instance.resize();
      onChartReady?.(instance);
    },
    [onChartReady],
  );

  const optionsPrime: EChartsOption = {
    ...options,
    textStyle: {
      fontFamily: 'Helvetica, Inter, sans-serif',
      ...options.textStyle,
    },
  };

  return (
    <Box ref={containerRef} h="full" w="full">
      <ReactEChartsCore
        ref={ref}
        echarts={echarts}
        notMerge={true}
        lazyUpdate={false}
        onChartReady={onChartReadyPrime}
        option={optionsPrime}
        style={{
          width: '100%',
          height: '100%',
          visibility: isVisible ? 'visible' : 'hidden',
        }}
      />
    </Box>
  );
});

export const ECharts = forwardRef<EChartsReact, Props>((props, ref) => {
  const { ...rest } = props;

  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return <EChartsSSR {...rest} />;
  } else {
    return <EChartsClient {...rest} ref={ref} />;
  }
});
