import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Input,
  Spacer,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { intersperse, ReportTemplateVariable } from '@piccolohealth/echo-common';

import { Dot, NumberedBadge, Spin } from '@piccolohealth/ui';
import { inflection, P, uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useReport } from '../../../../../../../context/ReportContext';
import { useAppContext } from '../../../../../../../hooks/useAppContext';
import {
  HistoricalVariableChartData,
  useHistoricalVariables,
} from '../../../../../../../hooks/useHistoricalVariables';
import { FormItem } from '../../../../../../forms/FormItem';
import { HookedFormItem } from '../../../../../../forms/hookform/HookedFormItem';
import { HookedSubmitButton } from '../../../../../../forms/hookform/HookedSubmitButton';
import { HookedTextArea } from '../../../../../../forms/hookform/HookedTextArea';
import { List, ListItem } from '../../../../../../generic/List';
import { CreateMediaAttachmentChartRequest } from '../MediaAttachmentZone';
import { ChartAttachmentContent } from './ChartAttachmentContent';
import { ChartAttachmentTooltip } from './ChartAttachmentTooltip';

export const GREEN = '#38A169';
export const BLUE = '#3182CE';

interface Props {
  reportId: string;
  onBack: () => void;
  onClose: () => void;
  onAttachChart: (value: CreateMediaAttachmentChartRequest) => void;
}

export const ChartAttachmentForm = (props: Props) => {
  const { reportId, onBack, onClose, onAttachChart } = props;
  const { organization } = useAppContext();
  const { reportTemplate } = useReport();

  const [measurementNameSearch, setMeasurementNameSearch] = React.useState('');
  const [hideNoHistory, setHideNoHistory] = React.useState(true);

  const methods = useForm<CreateMediaAttachmentChartRequest>({
    defaultValues: {
      id: uuid(),
      type: 'CHART',
      description: null,
      data: [],
      metadata: null,
    },
  });

  const { setValue, watch } = methods;
  const formValues = watch();
  const existingData = formValues.data;
  const isMaxVariables = existingData.length === 2;

  const setChartData = React.useCallback(
    (reportTemplateVariable: ReportTemplateVariable, data: HistoricalVariableChartData) => {
      // If the variable is already selected, remove it
      if (existingData.some((item) => item.series[0].id === reportTemplateVariable.label)) {
        setValue(
          'data',
          existingData.filter((item) => item.series[0].id !== reportTemplateVariable.label),
        );
        return;
      }

      // If there are already 2 variables selected, don't add another
      if (isMaxVariables) {
        return;
      }

      // Otherwise add the variable
      const color = existingData.length === 1 && existingData[0].color === GREEN ? BLUE : GREEN;

      setValue(
        'data',
        [
          ...existingData,
          {
            series: data.series,
            units: data.units,
            rangeInfo: data.rangeInfo,
            color,
          },
        ],
        { shouldDirty: true },
      );
    },
    [existingData, isMaxVariables, setValue],
  );

  const onSubmit = React.useCallback(
    (data: CreateMediaAttachmentChartRequest) => {
      onAttachChart(data);
      onClose();
    },
    [onAttachChart, onClose],
  );

  const { isLoading, allVariables, getHistory } = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const historicalVariables = React.useMemo(() => {
    if (isLoading) {
      return [];
    }

    return allVariables.map(({ reportTemplateVariable }) => {
      if (!reportTemplateVariable) {
        return null;
      }

      const history = getHistory(reportTemplateVariable.id);

      if (!history) {
        return null;
      }

      return {
        reportTemplateVariable,
        history,
      };
    });
  }, [allVariables, isLoading, getHistory]);

  const filteredVariables = React.useMemo(() => {
    const filteredWithoutReportTemplate = P.compact(historicalVariables);

    const filteredByName = filteredWithoutReportTemplate.filter(({ reportTemplateVariable }) => {
      return reportTemplateVariable
        ? reportTemplateVariable.label.toLowerCase().includes(measurementNameSearch.toLowerCase())
        : false;
    });

    const filteredByHasHistory = filteredByName.filter(({ history }) => {
      return hideNoHistory ? history.chart.isGraphable : true;
    });

    return filteredByHasHistory;
  }, [hideNoHistory, historicalVariables, measurementNameSearch]);

  if (isLoading) {
    return <Spin />;
  }

  const groupedBySite = P.groupBy(
    filteredVariables,
    ({ reportTemplateVariable }) => reportTemplateVariable.site,
  );

  const items = P.listify(groupedBySite, (vs, site) => {
    return {
      site,
      items: vs.map(({ reportTemplateVariable, history }) => {
        const historyCountText = history.chart.isGraphable
          ? `${history.allNonNil.length} ${inflection.inflect(
              'measurement',
              history.allNonNil.length,
            )}`
          : 'No history';

        const selectedItem =
          formValues.data.find((item) => item.series[0].id === reportTemplateVariable.label) ??
          null;

        const isDisabled = !history.chart.isGraphable;

        return {
          reportTemplateVariable,
          history,
          historyCountText,
          label: reportTemplateVariable.label,
          isDisabled,
          selectedItem,
        };
      }),
    };
  });

  const headerContent = (
    <HStack spacing={4}>
      {formValues.data.map(({ series, color }) => (
        <HStack key={`dot-${series[0].id}`} spacing={2}>
          <Dot size={3} bg={color} />
          <Text fontSize="md" fontWeight="semibold">
            {series[0].id}
          </Text>
        </HStack>
      ))}
    </HStack>
  );

  const sidebarContent = P.run(() => {
    if (P.isEmpty(items)) {
      return (
        <Text fontSize="sm" color="secondary">
          No measurements found
        </Text>
      );
    }

    const listItems = items.map(({ site, items }) => (
      <Box key={site} userSelect="none">
        <HStack px={2} alignItems="center" fontWeight="bold" color="gray.600">
          <Text fontSize="lg">{P.upperFirst(site)}</Text>
          <Spacer />
          <NumberedBadge count={items.length} />;
        </HStack>
        <Box>
          {items.map((item) => {
            return (
              <Tooltip
                key={item.reportTemplateVariable.id}
                isDisabled={!isMaxVariables || !!item.selectedItem}
                label="Only two measurements at a time can be attached. Please remove one to attach another."
                placement="right"
              >
                <ListItem
                  bg={item.selectedItem ? 'gray.100' : 'inherit'}
                  isDisabled={item.isDisabled}
                  cursor={!isMaxVariables || item.selectedItem ? 'pointer' : 'not-allowed'}
                  onClick={() =>
                    !item.isDisabled &&
                    setChartData(item.reportTemplateVariable, item.history.chart)
                  }
                >
                  <Stack spacing={0} w="full">
                    <Flex justify="space-between" align="center">
                      <Text fontSize="sm" fontWeight="semibold">
                        {item.reportTemplateVariable.label}
                      </Text>
                      {item.isDisabled && <ChartAttachmentTooltip />}
                    </Flex>
                    <Text color="gray.500" fontWeight="semibold" fontSize="xs">
                      {item.historyCountText}
                    </Text>
                  </Stack>
                  <Dot
                    ml={4}
                    size={3}
                    rounded="md"
                    bg={item.selectedItem ? item.selectedItem.color : 'transparent'}
                  />
                </ListItem>
              </Tooltip>
            );
          })}
        </Box>
      </Box>
    ));

    return intersperse(listItems, () => <Divider my={4} />);
  });

  return (
    <FormProvider {...methods}>
      <Flex h="full" w="full" flexDir="row" as="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Box w="xs" h="full" flexBasis="xs" flexGrow={0} flexShrink={0} borderRightWidth="1px">
          <Stack spacing={4} h="full">
            <Box mr={4}>
              <Input
                placeholder="Search by measurement name"
                size="sm"
                onChange={(e) => setMeasurementNameSearch(e.target.value)}
              />
            </Box>

            <FormItem label="Hide measurements without history" layout="horizontal">
              <Switch
                isChecked={hideNoHistory}
                onChange={(e) => setHideNoHistory(e.target.checked)}
              />
            </FormItem>

            <List h="full" overflowY="auto" pr={4} borderTopWidth="1px" pt={4}>
              {sidebarContent}
            </List>
          </Stack>
        </Box>
        <Flex flexDir="column" flex={1} px={4} mb={4}>
          <Stack spacing={4} h="full">
            <Heading size="sm">{headerContent}</Heading>
            <ChartAttachmentContent ssr={false} data={formValues.data} />
          </Stack>
        </Flex>
        <Flex w="3xs" flexGrow={0} flexShrink={0} flexBasis="3xs" borderLeftWidth="1px" px={4}>
          <Stack spacing={4} w="full">
            <Heading size="sm">Attach a chart</Heading>
            <HookedFormItem
              name="description"
              label="Description"
              helperText="A caption below your chart"
            >
              <HookedTextArea name="description" minRows={3} />
            </HookedFormItem>
            <Divider />
            <HStack>
              <Button onClick={onBack}>Back</Button>
              <Button onClick={onClose}>Close</Button>
              <HookedSubmitButton data-pw="attachChartSubmitButton">Save</HookedSubmitButton>
            </HStack>
          </Stack>
        </Flex>
      </Flex>
    </FormProvider>
  );
};
