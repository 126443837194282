import { Flex, Heading, Stack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  value: number;
}

export const InsightsValue = (props: Props) => {
  const { value } = props;

  return (
    <Flex direction="column" w="full">
      <Stack direction="row" align="center" spacing={4}>
        <Heading lineHeight={1.1} size="2xl">
          {value}
        </Heading>
      </Stack>
    </Flex>
  );
};
