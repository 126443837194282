import { Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import { Content } from '../../../components/generic/Content';
import { TabsRouter } from '../../../components/generic/TabsRouter';
import { MeasurementMappingsAndVariantsEditor } from './mappings/MeasurementMappingsAndVariantsEditor';
import { MeasurementRangeGroupsEditor } from './ranges/MeasurementRangeGroupsEditor';

export const Dicom = () => {
  return (
    <Content title="DICOM">
      <TabsRouter
        defaultIndex={0}
        h="100%"
        display="flex"
        flexDir="column"
        colorScheme="purple"
        isLazy
        routes={['mappings', 'ranges']}
        keepQueryParams
      >
        <TabList>
          <Tab key="mappings">Mappings</Tab>
          <Tab key="ranges">Ranges</Tab>
        </TabList>
        <TabPanels h="100%" overflowY="auto">
          <TabPanel key="mappings-tabpanel" h="100%" p={0}>
            <MeasurementMappingsAndVariantsEditor />
          </TabPanel>
          <TabPanel key="ranges-tabpanel" h="100%">
            <MeasurementRangeGroupsEditor />
          </TabPanel>
        </TabPanels>
      </TabsRouter>
    </Content>
  );
};
