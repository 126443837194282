import gql from 'graphql-tag';

export const MeasurementMappingsAndVariants = gql`
  query MeasurementMappingsAndVariants(
    $request: GetPaginatedMeasurementMappingsAndVariantsRequest
  ) {
    dicom {
      measurementMappingsAndVariants(request: $request) {
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        mappingsAndVariants {
          mapping {
            id
            alias
            name
            shortName
            site
            units
            precision
            properties {
              name
              value
            }
            rangeGroup {
              id
              label
              units
              ranges {
                level
                label
                sex
                age {
                  lt
                  lte
                  gt
                  gte
                }
                measurement {
                  lt
                  lte
                  gt
                  gte
                }
              }
              sources {
                name
                url
              }
            }
            createdAt
            updatedAt
          }
          variants {
            id
            mappingId
            originalMappingId
            hash
            name
            site
            units
            properties {
              name
              value
            }
            machine {
              manufacturer
              model
            }
            organizationStats {
              id
              name
              logo
              count
              machine {
                manufacturer
                model
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const UnusedMeasurementVariants = gql`
  query UnusedMeasurementVariants($request: GetPaginatedUnusedMeasurementVariantsRequest) {
    dicom {
      unusedMeasurementVariants(request: $request) {
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        variants {
          id
          mappingId
          originalMappingId
          hash
          name
          site
          units
          properties {
            name
            value
          }
          machine {
            manufacturer
            model
          }
          organizationStats {
            id
            name
            logo
            count
            machine {
              manufacturer
              model
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const Machines = gql`
  query Machines {
    dicom {
      machines {
        manufacturer
        model
      }
    }
  }
`;

export const MeasurementRangeGroups = gql`
  query MeasurementRangeGroups {
    dicom {
      measurementRangeGroups {
        id
        label
        units
        ranges {
          label
          level
          sex
          age {
            lt
            lte
            gt
            gte
          }
          measurement {
            lt
            lte
            gt
            gte
          }
        }
        sources {
          name
          url
        }
      }
    }
  }
`;
