import { Box, HStack } from '@chakra-ui/react';
import React from 'react';
import { DicomFilter } from '../../hooks/useDicomFilter';
import { NameFilterControl } from './NameFilterControl';
import { SiteFilterControl } from './SiteFilterControl';

interface Props {
  filter: DicomFilter;
}

export const MeasurementMappingsAndVariantsFilterHeader = (props: Props) => {
  const { filter } = props;

  return (
    <HStack spacing={4}>
      <Box width="2xs">
        <NameFilterControl value={filter.mappingNameFilter} onChange={filter.onMappingNameFilter} />
      </Box>
      <Box width="2xs">
        <SiteFilterControl value={filter.mappingSiteFilter} onChange={filter.onMappingSiteFilter} />
      </Box>
    </HStack>
  );
};
