import { getMeasurementRangeLevelColor, MeasurementRange } from '@piccolohealth/echo-common';
import { Dot } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';

interface Props {
  range: MeasurementRange | null;
}

export const MeasurementRangeDot = (props: Props) => {
  const { range } = props;

  if (P.isNil(range)) {
    return <Dot flexShrink={0} bg="gray.400" size={2} />;
  }

  const color = `${getMeasurementRangeLevelColor(range.level)}.500`;

  return <Dot flexShrink={0} bg={color} size={2} />;
};
