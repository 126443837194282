import { Input } from '@chakra-ui/react';
import React from 'react';
import { FormItem } from '../../../../components/forms/FormItem';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const SiteFilterControl = (props: Props) => {
  const { value, onChange } = props;

  return (
    <FormItem id="site" label="Site">
      <Input
        size="sm"
        defaultValue={value}
        placeholder="Search by site"
        onChange={(event) => onChange(event.target.value)}
      />
    </FormItem>
  );
};
