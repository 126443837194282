import { Divider, InputGroup, InputRightAddon } from '@chakra-ui/react';
import {
  FeatureFlag,
  getRelevantRangesForCriteria,
  getReportTemplateVariableById,
  isMeasurementCriteriaInRange,
  isReportTemplateStaticVariable,
  MeasurementRange,
  MeasurementRangeResult,
  PiccoloError,
  renderNumericalRange,
} from '@piccolohealth/echo-common';
import { ChakraV3, Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaExternalLinkAlt, FaList, FaRegClock } from 'react-icons/fa';
import { HookedInput } from '../../components/forms/hookform/HookedInput';
import { Error } from '../../components/generic/Error';
import { VisibleWithFeatureFlag } from '../../components/generic/VisibleWithFeatureFlag';
import { useReport } from '../../context/ReportContext';
import { useAppContext } from '../../hooks/useAppContext';
import { useHistoricalVariables } from '../../hooks/useHistoricalVariables';
import { MeasurementRangeGroupTable } from './MeasurementRangeGroupTable';
import { MeasurementsChart } from './MeasurementsChart';

interface MeasurementPopoverContentProps {
  variableId: string;
}

const MeasurementPopoverContent = (props: MeasurementPopoverContentProps) => {
  const { variableId } = props;
  const { organization } = useAppContext();
  const { reportId, reportTemplate } = useReport();
  const [hoveredDataIndex, setHoveredDataIndex] = React.useState<number | null>(null);

  const { isLoading, getHistory } = useHistoricalVariables({
    organizationId: organization.id,
    reportId,
    reportTemplate,
  });

  const history = getHistory(variableId);

  if (!history) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'InternalClientError',
            message: 'Error getting measurement history. Could not find history for measurement.',
          })
        }
      />
    );
  }

  const measurementChartContent = P.run(() => {
    if (!history.chart.isGraphable) {
      return <Empty h="full" title="No measurement history found" />;
    }

    return (
      <MeasurementsChart
        ssr={false}
        data={[history.chart]}
        onHoverDataIndex={setHoveredDataIndex}
      />
    );
  });

  const normalRangesContent = P.run(() => {
    const applicableRangesForSexAndAge: MeasurementRange[] = getRelevantRangesForCriteria(
      history.chart.rangeInfo.result?.rangeGroup.ranges ?? [],
      history.chart.rangeInfo.criteria,
    );

    const uniqueAge = P.first(
      Array.from(
        new Set(
          applicableRangesForSexAndAge.map((range) => range.age && renderNumericalRange(range.age)),
        ),
      ),
    );
    const uniqueSex = P.first(
      Array.from(new Set(applicableRangesForSexAndAge.map((range) => range.sex))),
    );

    // This is similar to ranges.ts getRangeResult function
    // Except we are using the existing range info we have,
    // just calculating a different range result based on
    // the criteria of the hovered measurement, rather than
    // variables in the report.
    const rangeResult: MeasurementRangeResult | null = P.run(() => {
      const rangeInfo = history.chart.rangeInfo;

      if (!rangeInfo?.result) {
        return null;
      }

      const measurement = P.isNil(hoveredDataIndex)
        ? history.chart.rangeInfo.criteria.measurement
        : history.chart.series[0].data[hoveredDataIndex].y;

      const criteria = rangeInfo.criteria;
      const ranges = rangeInfo.result.rangeGroup.ranges;

      const matchingRange =
        ranges.find((range) =>
          isMeasurementCriteriaInRange(range, {
            ...criteria,
            measurement,
          }),
        ) ?? null;

      return {
        range: matchingRange,
        rangeGroup: rangeInfo.result.rangeGroup,
      };
    });

    if (P.isEmpty(applicableRangesForSexAndAge) || P.isNil(rangeResult)) {
      return <ChakraV3.Text fontSize="sm">No normal ranges available</ChakraV3.Text>;
    }

    return (
      <ChakraV3.Stack>
        <ChakraV3.HStack ml={2}>
          <ChakraV3.Text fontSize="xs" textTransform="capitalize">
            <strong>Age:</strong> {uniqueAge ?? 'Any'}
          </ChakraV3.Text>
          <ChakraV3.Text fontSize="xs" textTransform="capitalize">
            <strong>Sex:</strong> {uniqueSex ?? 'Any'}
          </ChakraV3.Text>
        </ChakraV3.HStack>
        <MeasurementRangeGroupTable
          criteria={history.chart.rangeInfo.criteria}
          rangeResult={rangeResult}
        />
        <ChakraV3.Collapsible.Root fontSize="sm">
          <ChakraV3.Collapsible.Trigger>
            <ChakraV3.Button
              p={0}
              size="xs"
              fontSize="xs"
              colorPalette="gray"
              variant="plain"
              _hover={{ textDecoration: 'underline' }}
            >
              View sources
            </ChakraV3.Button>
          </ChakraV3.Collapsible.Trigger>
          <ChakraV3.Collapsible.Content>
            <ChakraV3.Stack>
              {rangeResult.rangeGroup.sources.map((source, index) => (
                <ChakraV3.HStack key={index} fontSize="xs">
                  <ChakraV3.Icon>
                    <FaExternalLinkAlt />
                  </ChakraV3.Icon>
                  <ChakraV3.Link
                    variant="plain"
                    target="_blank"
                    href={source.url}
                    focusRing="none"
                    lineClamp={1}
                  >
                    {source.name}
                  </ChakraV3.Link>
                </ChakraV3.HStack>
              ))}
            </ChakraV3.Stack>
          </ChakraV3.Collapsible.Content>
        </ChakraV3.Collapsible.Root>
      </ChakraV3.Stack>
    );
  });

  return (
    <ChakraV3.FadeLoader w="full" isLoading={isLoading}>
      <ChakraV3.Stack w="full" gap={4}>
        <ChakraV3.Stack w="full" h="2xs" gap={2}>
          <ChakraV3.HStack>
            <ChakraV3.Icon>
              <FaRegClock />
            </ChakraV3.Icon>
            <ChakraV3.Text fontWeight="semibold" fontSize="md">
              Historical values
            </ChakraV3.Text>
          </ChakraV3.HStack>
          {measurementChartContent}
        </ChakraV3.Stack>

        <Divider />

        <VisibleWithFeatureFlag featureFlag={FeatureFlag.NormalRanges}>
          <ChakraV3.Stack w="full " minH={16} gap={2}>
            <ChakraV3.HStack>
              <ChakraV3.Icon>
                <FaList />
              </ChakraV3.Icon>
              <ChakraV3.Text fontWeight="semibold" fontSize="md">
                Normal ranges
              </ChakraV3.Text>
              <ChakraV3.Spacer />
            </ChakraV3.HStack>
            {normalRangesContent}
          </ChakraV3.Stack>
        </VisibleWithFeatureFlag>
      </ChakraV3.Stack>
    </ChakraV3.FadeLoader>
  );
};

interface Props {
  variableId: string;
  isDisabled?: boolean;
}

export const MeasurementPopover = (props: Props) => {
  const name = `variables.${props.variableId}.value`;
  const reportContext = useReport();

  const isInputDisabled = props.isDisabled ?? reportContext.isDisabled;

  const templateVariable = getReportTemplateVariableById(
    reportContext.reportTemplate.variables.filter(isReportTemplateStaticVariable),
    props.variableId,
  );

  return (
    <ChakraV3.Stack
      bg="white"
      align="start"
      justify="start"
      minH="sm"
      w="2xl"
      px={4}
      py={4}
      rounded="lg"
      borderColor="gray.200"
      borderWidth="1px"
      shadow="popover"
      gap={4}
    >
      <ChakraV3.HStack align="start" w="full">
        <ChakraV3.Text fontWeight="semibold" fontSize="lg">
          {templateVariable?.label}
        </ChakraV3.Text>
        <ChakraV3.Spacer />
        <InputGroup size="sm" maxW={36}>
          <HookedInput
            name={name}
            borderRightRadius={templateVariable?.units ? 0 : 'md'}
            isDisabled={isInputDisabled}
          />
          {templateVariable?.units && (
            <InputRightAddon borderRightRadius="md">{templateVariable.units}</InputRightAddon>
          )}
        </InputGroup>
      </ChakraV3.HStack>
      <ChakraV3.Separator />
      <MeasurementPopoverContent variableId={props.variableId} />
    </ChakraV3.Stack>
  );
};
