import { Box, Button, HStack, Spacer, Stack } from '@chakra-ui/react';
import { LooseObject } from '@piccolohealth/echo-common';
import { P } from '@piccolohealth/util';
import React, { Suspense } from 'react';
import { useController } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useReport } from '../../../../../../context/ReportContext';
import { MeasurementChartData } from '../../../../../../features/measurements/MeasurementsChart';
import { BaseNodeProps } from '../../../../../../utils/craftjs';
import { showModal } from '../../../../../generic/Modal';
import { useSSRNode } from '../../../../hooks/useSSRNode';
import { AttachedMedia } from './AttachedMedia';
import { AttachmentButton } from './AttachmentButton';
const MediaAttachmentModal = React.lazy(() => import('./MediaAttachmentModal'));

export interface CreateMediaAttachmentChartRequest {
  id: string;
  type: 'CHART';
  description: string | null;
  metadata: LooseObject | null;
  data: MeasurementChartData[];
}

export interface CreateMediaAttachmentStudyImageRequest {
  id: string;
  type: 'STUDY_IMAGE';
  description: string | null;
  data: any;
  metadata: LooseObject | null;
}

export interface CreateMediaAttachmentImageUploadRequest {
  id: string;
  type: 'IMAGE_UPLOAD';
  description: string | null;
  data: any;
  metadata: LooseObject | null;
}

export type CreateMediaAttachmentRequest =
  | CreateMediaAttachmentChartRequest
  | CreateMediaAttachmentStudyImageRequest
  | CreateMediaAttachmentImageUploadRequest;

export interface Props extends BaseNodeProps {
  variableId: string;
}

export const MediaAttachmentZone = (props: Props) => {
  const { ssr, variableId } = props;

  const {
    connectors: { connect, drag },
  } = useSSRNode(props);

  const { reportId, isDisabled } = useReport();

  const formName = `variables.${variableId}.value`;
  const { field } = useController({ name: formName });
  const value = React.useMemo(
    () => (field.value ?? []) as CreateMediaAttachmentRequest[],
    [field.value],
  );

  const onAttachMedia = React.useCallback(
    (value: CreateMediaAttachmentRequest) => {
      field.onChange([...field.value, { ...value, isTouched: true }]);
    },
    [field],
  );

  const onRemoveMedia = React.useCallback(
    (id: string) => {
      field.onChange(value.filter((item) => item.id !== id));
    },
    [field, value],
  );

  const modalId = `media-attachment-modal-${variableId}`;

  const mediaItems = value.map((item) => {
    return (
      <AttachedMedia
        key={item.id}
        ssr={ssr ?? false}
        isDisabled={isDisabled}
        value={item}
        onClickRemove={onRemoveMedia}
      />
    );
  });

  const content = P.run(() => {
    if (mediaItems.length === 0) {
      return !ssr ? (
        <AttachmentButton
          isDisabled={isDisabled}
          heading="Attach media"
          subHeading="Attach media to the report"
          icon={FaPlus}
          onClick={() => showModal(modalId)}
        />
      ) : (
        <Box w="full" h="full" />
      );
    }

    return mediaItems;
  });

  const addAnotherAttachmentButton = P.run(() => {
    if (ssr || mediaItems.length === 0) {
      return null;
    }

    // Allow only max 2 items attached
    return (
      <HStack w="full">
        <Spacer />
        <Button
          variant="link"
          onClick={() => showModal(modalId)}
          isDisabled={mediaItems.length === 2 || isDisabled}
        >
          Add another attachment
        </Button>
      </HStack>
    );
  });

  return (
    <Stack ref={(ref) => connect(drag(ref))} h="full" w="full">
      {!ssr && (
        <Suspense fallback={null}>
          <MediaAttachmentModal id={modalId} reportId={reportId} onAttachMedia={onAttachMedia} />
        </Suspense>
      )}
      {addAnotherAttachmentButton}
      <HStack align="center" justify="center" h="full" spacing={4}>
        {content}
      </HStack>
    </Stack>
  );
};

const MediaAttachmentZoneSettings = () => {
  return null;
};

MediaAttachmentZone.defaultProps = {
  variableId: 'mediaAttachments',
};

MediaAttachmentZone.craft = {
  name: 'MediaAttachmentZone',
  props: MediaAttachmentZone.defaultProps,
  related: {
    settings: MediaAttachmentZoneSettings,
  },
};
