import { Box, Flex } from '@chakra-ui/react';
import { Frame, SerializedNodes } from '@craftjs/core';
import {
  formValuesFromReportTemplate,
  ReportFormValues,
  reportFormValuesDiff,
  ReportTemplate,
  ReportVariable,
} from '@piccolohealth/echo-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCustomCompareEffect } from 'react-use';
import { ReportContextProvider } from '../../../context/ReportContext';
import { useAppContext } from '../../../hooks/useAppContext';
import { CraftJsEditor } from '../../generic/CraftJsEditor';
import { LeftSidebar } from './LeftSidebar';
import { RenderNode } from './RenderNode';
import { RightSidebar } from './RightSidebar';
import { ToolboxComponent } from './Toolbox';

interface Props {
  // TODO: Add proper type for resolvers
  resolvers: any;
  reportTemplate: ReportTemplate;
  variables: ReportVariable[];
  nodes: SerializedNodes;
  componentGroups: {
    name: string;
    components: ToolboxComponent[];
  }[];
  onSave: (nodes: SerializedNodes) => Promise<void>;
}

export const Editor = (props: Props) => {
  const { resolvers, reportTemplate, variables, nodes, componentGroups, onSave } = props;
  const { organization } = useAppContext();

  const initialValues: ReportFormValues = formValuesFromReportTemplate(
    reportTemplate.statementSites,
    reportTemplate.variables,
    variables,
  );

  const methods = useForm({
    defaultValues: initialValues as any,
  });
  const { reset } = methods;

  useCustomCompareEffect(
    () => {
      reset(initialValues);
    },
    [initialValues],
    ([prev], [next]) => !reportFormValuesDiff(prev, next).isDiff,
  );

  return (
    <ReportContextProvider
      reportId={reportTemplate.id}
      changesetId={null}
      timezone={organization.timezone}
      reportTemplate={reportTemplate}
      hasFeature={organization.hasFeature}
      isDisabled={false}
    >
      <FormProvider {...methods}>
        <CraftJsEditor key={reportTemplate.id} resolver={resolvers} onRender={RenderNode}>
          <Flex h="100%" style={{ border: '1px solid #e8e8e8' }}>
            <Box borderRightColor="secondary" borderRightWidth="1px" borderRightStyle="solid">
              <LeftSidebar componentGroups={componentGroups} onSave={onSave} />
            </Box>

            <Box
              w="100%"
              h="100%"
              p={2}
              bg="#f5f5f5"
              alignItems="center"
              flexDirection="column"
              display="flex"
              overflowY="auto"
            >
              <Frame data={nodes} />
            </Box>
            <Box
              w="100%"
              maxW="480px"
              minW="220px"
              flexShrink={2}
              borderLeftColor="secondary"
              borderLeftWidth="1px"
              borderLeftStyle="solid"
            >
              <RightSidebar />
            </Box>
          </Flex>
        </CraftJsEditor>
      </FormProvider>
    </ReportContextProvider>
  );
};
