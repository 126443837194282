import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FileUploadItemsProgress, useFileUpload, FileUploadControl } from '@piccolohealth/ui';
import { FILE_SIZE_LIMIT_32MB_BYTES, renderPiccoloError } from '@piccolohealth/echo-common';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { getReportBaseKey } from '../../graphql/hooks/useReportQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { asPiccoloError } from '../../utils/errors';
import { createModal } from '../generic/Modal';

interface Props {
  reportId: string;
}

export const ReportAttachmentsUploadModal = createModal<Props>((props) => {
  const { reportId, modal } = props;
  const { config, organization, queryClient, auth } = useAppContext();
  const { getAccessTokenSilently } = auth;

  const sendFile = React.useCallback(
    async (
      file: File,
      onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
    ) => {
      const url = `${config.api.url}/organizations/${organization.id}/reports/${reportId}/attachments`;
      const accessToken = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append('files', file);

      await axios(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        onUploadProgress: (event) => {
          if (!event.total) {
            return;
          }

          onProgress({
            percentage: (event.loaded / event.total) * 100,
            total: event.total,
            loaded: event.loaded,
          });
        },
      }).catch((error) => {
        const piccoloError = asPiccoloError(error);
        piccoloError.message = renderPiccoloError(piccoloError).message;
        throw piccoloError;
      });

      onProgress({
        percentage: 100,
      });

      await queryClient.invalidateQueries(
        getReportBaseKey({
          organizationId: organization.id,
          reportId,
        }),
      );
    },
    [reportId, organization.id, config.api.url, queryClient, getAccessTokenSilently],
  );

  const fileUploader = useFileUpload({
    sendFile,
    concurrency: 4,
    autoStart: true,
    maxFileSize: FILE_SIZE_LIMIT_32MB_BYTES,
  });

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="2xl">
      <ModalOverlay />
      <ModalContent h="2xl">
        <ModalHeader>Add attachments</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          {_.isEmpty(fileUploader.files) && (
            <FileUploadControl
              multiple={true}
              files={fileUploader.files}
              onFilesChange={fileUploader.onFilesChange}
              status={fileUploader.status}
            />
          )}
          <FileUploadItemsProgress files={_.values(fileUploader.fileProgress)} />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={fileUploader.reset} size="sm">
            Reset
          </Button>
          <Button
            colorScheme="purple"
            mr={3}
            onClick={modal.hide}
            size="sm"
            data-pw="reportAttachmentUploadModalCloseButton"
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
