import { MeasurementRangeGroup } from '@piccolohealth/echo-common';
import React from 'react';
import { TreeItem, TreeItemIndex, TreeViewState } from 'react-complex-tree';
import { generateRangeGroupsTree, TreeData } from '../components/ranges/utils';

export interface UseRangeGroupsTreeStateOptions {
  rangeGroups: MeasurementRangeGroup[];
}

export const useRangeGroupsTreeState = (options: UseRangeGroupsTreeStateOptions) => {
  const [treeState, setTreeState] = React.useState<Record<TreeItemIndex, TreeItem<TreeData>>>({});
  const [viewState, setViewState] = React.useState<TreeViewState<string>>({});

  const onFocusItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          focusedItem: item.index,
        },
      };
    });
  };

  const onExpandItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          expandedItems: [...(prev[treeId]?.expandedItems ?? []), item.index],
        },
      };
    });
  };

  const onCollapseItem = (item: TreeItem<TreeData>, treeId: string) => {
    setViewState((prev) => {
      return {
        ...prev,
        [treeId]: {
          ...prev[treeId],
          expandedItems: [...(prev[treeId]?.expandedItems ?? [])].filter((i) => i !== item.index),
        },
      };
    });
  };

  React.useEffect(() => {
    setTreeState(generateRangeGroupsTree(options.rangeGroups));
  }, [options.rangeGroups]);

  return {
    treeState,
    viewState,
    onFocusItem,
    onExpandItem,
    onCollapseItem,
  };
};
