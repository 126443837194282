import { HStack, Stack, Text } from '@chakra-ui/react';
import { MeasurementRangeResult } from '@piccolohealth/echo-common';
import { FancyDate } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { ChakraProvider } from '../../theme/chakra';
import { MeasurementRangeDot } from './MeasurementRangeDot';

interface Props {
  seriesName: string;
  timestamp: DateTime;
  value: number | null;
  units: string | null;
  rangeResult: MeasurementRangeResult | null;
}

export const MeasurementsChartTooltip = (props: Props) => {
  const { seriesName, timestamp, value, units, rangeResult } = props;

  return (
    <ChakraProvider>
      <Stack spacing={0}>
        <FancyDate date={timestamp} direction="row" />
        <HStack>
          {rangeResult && <MeasurementRangeDot range={rangeResult.range ?? null} />}
          <Text fontWeight="semibold">{seriesName}</Text>
          <Text>
            {value ?? 'N/A'} {units ?? ''}
          </Text>
        </HStack>
      </Stack>
    </ChakraProvider>
  );
};
